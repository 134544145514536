<script>
import { Button, Table } from 'ant-design-vue'
import DetailFormGroup from '../../../components/DetailFormGroup'
import api from '@/command/api'
import CardIconLabelLocal from '../../../components/CardIconLabelLocal'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction } from '../../../command/netTool'
import { activeTypeList } from '@/utils/textFile'

export default {
  name: 'farmersBrigadeDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        farmTravelRouteDayVOS: [
          {
            travelRouteDetailVOS: []
          }
        ]
      },
      shopDetail: {
        storeId: '',
        storeName: '',
        activeType: '',
        latitude: '',
        longitude: '',
        storeLabel: [],
        farmLabelDataVOS: []
      },
      mainBodyTable: [],
      farmLabelDataVOS: [],
      typeArrData: [],
      setdata: {
        latitude: '',
        longitude: ''
      }
    }
  },
  computed: {
    routeType() {
      return !this.shopDetail.activeType ? '1' : '2'
    }
  },
  watch: {
    'shopDetail.activeType'(a, b) {
      if (a) {
        this.onSearchDianpu()
      }
    }
  },
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/commodity/farmTravelRoute/${id}`
        })
        .then(result => {
          this.farmLabelDataVOS = result.farmLabelDataVOS
          this.$forceUpdate()
        })
  },
  methods: {
    getLatLng() {
      let a = this.detail.farmTravelRouteDayVOS.map(e => e.travelRouteDetailVOS)
      a.some(e => (this.setdata = e.find(e => e.activeType != null)))

      if (this.setdata == undefined) {
        this.setdata = {
          latitude: '',
          longitude: ''
        }
      }
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称',
            type: 'input',
            cols: 12,
            key: 'routeName',
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '排序序号（0-100）',
            type: 'inputNumber',
            cols: 12,
            key: 'sort',
            props: {
              min: 0,
              max: 100
            }
          }
        ]
      }
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: () => (
          <div>
            <span style={{ color: '#f5222d' }}>*</span>Banner（尺寸750*420PX）限制1张
          </div>
        ),
        data: [
          {
            type: 'upload',
            maxLength: 1,
            cols: 24,
            key: 'bannerUrl',
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    onRemove(index) {
      if (this.detail.farmTravelRouteDayVOS.length == 1) {
        this.$message.error('保留最后一项')
      } else {
        this.detail.farmTravelRouteDayVOS.splice(index - 1, 1)
      }
    },
    onCustomSuccess(index, form, isLineedit, lineindex) {
      const { data, setHidden } = form
      if (isLineedit) {
        this.detail.farmTravelRouteDayVOS[index - 1].travelRouteDetailVOS.splice(lineindex, 1, data)
      } else {
        this.detail.farmTravelRouteDayVOS[index - 1].travelRouteDetailVOS.push(data)
      }

      setHidden()
    },
    onCustom(index, formData = {}, isLineedit, lineindex) {
      let formDatacopy = JSON.parse(JSON.stringify(formData))
      apiTool.showDrawer({
        title: '自定义点',
        width: '600px',
        form: [
          {
            name: '自定义图片（宽=750  高<=1334PX）限制3张',
            type: 'upload',
            maxLength: 3,
            key: 'pictureUrl'
          },
          {
            name: '标题',
            type: 'input',
            key: 'subTitle'
          },
          {
            name: '自定义内容',
            type: 'textArea',
            key: 'introduction'
          }
        ],
        formData: formDatacopy,
        success: form => this.onCustomSuccess(index, form, isLineedit, lineindex),
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    // 店铺弹出table
    renderOverlay({ setShow } = {}) {
      let that = this

      return (
        <div style={{ background: 'white', border: '1px solid #333333' }}>
          <Table
            pagination={false}
            dataSource={this.mainBodyTable}
            customRow={record => {
              return {
                on: {
                  // 事件
                  click: event => {
                    that.shopDetail.storeName = record.storeName
                    that.shopDetail.storeId = record.storeId
                    that.shopDetail.latitude = record.latitude
                    that.shopDetail.longitude = record.longitude
                    that.shopDetail.pictureUrl = record.bannerUrl
                    that.shopDetail.introduction = record.content
                    that.getTypeData()
                    setShow(false)
                  }
                }
              }
            }}
            columns={[
              {
                dataIndex: 'storeName',
                title: '店铺名称'
              },
              {
                dataIndex: 'foodDriveLabel',
                title: '农家乐(数量)'
              },
              {
                dataIndex: 'distance',
                title: '距离线路中心点'
              }
            ]}
          />
        </div>
      )
    },
    onSearchDianpu(val = '') {
      let url = `/api/commodity/farmMainBody/getMainBodyForRoute?lat=${this.setdata.latitude}&lng=${this.setdata.longitude}&typeCode=${this.shopDetail.activeType}&current=1&size=10`
      if (this.shopDetail.storeName != '') {
        url += `&shopNmae=${val}`
      }
      api.command.searchPage
        .call(this, {
          url
        })
        .then(data => {
          this.mainBodyTable = data.map(el => {
            return {
              storeName: el.mainBodyName,
              foodDriveLabel: el.foodDriveLabel,
              distance: el.distance,
              storeId: el.id,
              farmLabelDataVOS: el.farmLabelDataVOS,
              latitude: el.latitude,
              longitude: el.longitude,
              bannerUrl: el.bannerUrl,
              content: el.content
            }
          })
          this.$forceUpdate()
        })
    },
    getTypeData() {
      const { storeId, activeType } = this.shopDetail
      let that = this
      return new Promise(resolve => {
        getAction(`/api/base/farmLabelData/selectLabelForImpress?shopId=${storeId}&typeCode=${activeType}`).then(
          result => {
            let dataId = (that.shopDetail.farmLabelDataVOS || []).map(e => e.labelName)
            let arr = result.data.map(e => {
              return {
                ...e,
                name: e.labelName,
                value: e.id,
                select: dataId.indexOf(e.labelName) > -1
              }
            })
            this.typeArrData = arr
            resolve(arr)
          }
        )
      })
    },
    onShowDown(index, formData = {}, isLineedit, lineindex) {
      this.getLatLng()
      let _this = this
      _this.shopDetail = JSON.parse(JSON.stringify(formData))
      if (_this.shopDetail.storeId && _this.shopDetail.activeType) {
        _this.getTypeData().then(result => {
          this.onOpen(index, result, isLineedit, lineindex)
        })
      } else {
        this.onOpen(index, [], isLineedit, lineindex)
      }
    },
    onOpen(index, typeArrData = [], isLineedit, lineindex) {
      let that = this
      apiTool.showDrawer({
        title: '添加店铺',
        width: '600px',
        formData: this.shopDetail,
        typeData() {
          return {
            farmLabelDataVOS: that.typeArrData
          }
        },
        form: [
          {
            name: '类型',
            type: 'select',
            key: 'activeType',
            typeData: activeTypeList,
            defaultValue: '1'
          },
          {
            name: '店铺',
            type: 'dropDownInput',
            valueKey: 'storeId',
            cols: 24,
            key: 'storeName',
            renderOverlay: this.renderOverlay,
            inputProps: {
              type: 'InputSearch',
              addonBefore: () => {
                return <a-icon type="search" />
              },
              placeholder: '搜索店铺'
            },
            onInputSearch: this.onSearchDianpu
          },
          {
            type: 'labelGroup',
            name: '店铺显示标签（4个内）',
            key: 'farmLabelDataVOS'
          },
          {
            name: '店铺简介（选择店铺后自动获取，纯文本）',
            type: 'textArea',
            key: 'introduction'
          }
        ],
        success: form => this.onshopSuccess(index, form, isLineedit, lineindex),
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    onshopSuccess(index, form, isLineedit = false, lineindex) {
      const { data, setHidden } = form

      if (!data.storeId) {
        this.$message.warning('请正确输入店铺！')
        return false
      }

      let dataLength = data.farmLabelDataVOS.filter(e => e.select == true)
      if (dataLength.length > 4) {
        this.$message.warning('店铺显示标签请保持在四个内！')
        return false
      }

      data.farmLabelDataVOS = data.farmLabelDataVOS
        .filter(e => e.select == true)
        .map(e => {
          return { ...e, labelName: e.name }
        })
      if (isLineedit) {
        this.detail.farmTravelRouteDayVOS[index - 1].travelRouteDetailVOS.splice(lineindex, 1, data)
      } else {
        this.detail.farmTravelRouteDayVOS[index - 1].travelRouteDetailVOS.push(data)
      }

      // 去重
      this.farmLabelDataVOS.push(...data.farmLabelDataVOS)
      let obj = {}
      let arrType = this.farmLabelDataVOS.reduce((item, next) => {
        obj[next.labelName] ? '' : (obj[next.labelName] = true && item.push(next))
        return item
      }, [])

      this.farmLabelDataVOS = arrType.map(e => {
        return {
          ...e,
          light: e.light || 0
        }
      })

      this.$forceUpdate()
      setHidden()
    },
    renderTableTop(index) {
      return (
        <div class="TableTop">
          <h3 style={{ paddingLeft: '20px' }}>{'第' + index + '天'}</h3>
          <div>
            <a-button
              ghost
              type="danger"
              icon="delete"
              style={{ marginRight: '20px' }}
              onClick={() => this.onRemove(index)}
            >
              删除线路
            </a-button>
            <a-dropdown>
              <a-button ghost type="primary">
                +添加旅游点
              </a-button>
              <a-menu slot="overlay">
                <a-menu-item onClick={() => this.onCustom(index)}>自定义</a-menu-item>
                <a-menu-item onClick={() => this.onShowDown(index)}>店铺</a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      )
    },
    getForm3(indexID, travelRouteDetailVOS) {
      let _this = this
      return {
        type: 'table',
        showFootButton: false,
        header: () => this.renderTableTop(indexID),
        havedrag: true,
        showPagination: false,
        showRowSelect: false,
        columns: [
          {
            dataIndex: 'a1',
            align: 'left',
            title: '拖动排序',
            width: 40,
            customRender: function() {
              return <a-icon type="menu" />
            }
          },
          {
            dataIndex: 'activeType',
            align: 'left',
            title: '类型',
            width: 20,
            customRender: function(text, records) {
              if (records.activeType == null) {
                return '自定义'
              } else {
                return activeTypeList.find(f => f.value == records.activeType)?.name || ''
              }
            }
          },
          {
            dataIndex: 'pictureUrl',
            align: 'left',
            title: '图片',
            width: 100,
            type: 'image'
          },
          {
            dataIndex: 'introduction',
            align: 'left',
            title: '简介',
            width: 100,
            customRender: (text, records) => {
              return <div class="text-hidden">{text}</div>
            }
          },
          {
            dataIndex: 'storeName',
            align: 'left',
            title: '商家信息',
            width: 150
          },
          {
            dataIndex: 'a2',
            align: 'center',
            title: '显示标签',
            width: 100,
            getData: records => (records.farmLabelDataVOS || []).map(e => e.labelName),
            type: 'tagGroup'
          },
          {
            dataIndex: 'a10',
            align: 'center',
            width: 60,
            title: '操作',
            type: 'buttonGroup',
            typeData: ({ records, item, index }) => {
              return [
                {
                  name: '编辑',
                  onClick: () => {
                    if (records.activeType == null) {
                      _this.onCustom(indexID, records, true, index)
                    } else {
                      _this.onShowDown(indexID, records, true, index)
                    }
                  }
                },
                {
                  name: '删除',
                  popTitle: '确认是否删除吗?',
                  onClick: () => {
                    this.detail.farmTravelRouteDayVOS[indexID - 1].travelRouteDetailVOS.splice(index, 1)
                  }
                }
              ]
            }
          }
        ],
        dataSource: travelRouteDetailVOS
      }
    },
    renderTables() {
      return this.detail.farmTravelRouteDayVOS.map((item, i) => {
        return this.getForm3(i + 1, item.travelRouteDetailVOS)
      })
    },
    getForm4() {
      return {
        type: 'row',
        children: [
          <CardIconLabelLocal
            isAddBtn={true}
            title={'线路标签(4个内)'}
            pubBool={false}
            cols={24}
            style={{
              marginBottom: '10px',
              minHeight: '200px'
            }}
            icons={this.farmLabelDataVOS}
            data={this.getLabelForm()}
          />
        ]
      }
    },
    getLabelForm() {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName'
        }
        // {
        //   name: '标签类型',
        //   type: 'radioButton',
        //   key: 'publicLabel',
        //   typeData: [
        //     {
        //       name: '店铺私有',
        //       value: '2'
        //     },
        //     {
        //       name: '公用标签库',
        //       value: '1'
        //     }
        //   ]
        // }
        // {
        //   name: '标签分类',
        //   type: 'iconClass',
        //   key: 'iconUrl'
        // }
      ]
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            let dataLength = this.farmLabelDataVOS.filter(e => e.light == '1')
            if (dataLength.length > 4) {
              this.$message.warning('线路标签请保持在四个之内！')
              return
            }

            this.detail.farmTravelRouteDayVOS = this.detail.farmTravelRouteDayVOS.map((item, index) => {
              return {
                dayNum: `第${index + 1}天`,
                travelRouteDetailVOS: item.travelRouteDetailVOS
              }
            })

            try {
              this.detail.farmTravelRouteDayVOS.forEach(e1 => {
                if (e1.travelRouteDetailVOS.length > 0) {
                  e1.travelRouteDetailVOS.forEach(e2 => {
                    if (e2.activeType) {
                      if (e2.farmLabelDataVOS.length > 0) {
                        let a = e2.farmLabelDataVOS.map(e3 => e3.value || e3.id)
                        let b = e2.farmLabelDataVOS.map(e3 => e3.labelName)
                        e2.storeLabel = a.toString()
                        e2.storeLabelName = b.toString()
                      } else {
                        e2.storeLabel = null
                        e2.storeLabelName = null
                      }
                    }
                  })
                } else {
                  this.$message.warning('请填写线路！')
                  throw new Error('结束')
                }
              })
            } catch (error) {
              console.log(error)
              return
            }

            if (!this.detail.id) {
              api.command
                .create({
                  url: '/commodity/farmTravelRoute',
                  params: {
                    ...this.detail,
                    farmLabelDataVOS: this.farmLabelDataVOS,
                    upDown: '0'
                  },
                  isPost: false
                })
                .then(data => {
                  setTimeout(() => {
                    this.$router.push('/marketingCenter/farmersBrigade')
                  }, 100)
                })
            } else {
              api.command.edit
                .call(this, {
                  url: '/commodity/farmTravelRoute',
                  params: {
                    ...this.detail,
                    farmLabelDataVOS: this.farmLabelDataVOS
                  },
                  isPost: false
                })
                .then(data => {
                  setTimeout(() => {
                    this.$router.push('/marketingCenter/farmersBrigade')
                  }, 100)
                })
            }
          }
        },
        {
          name: '取消',
          onClick: () => {
            setTimeout(() => {
              this.$router.push('/marketingCenter/farmersBrigade')
            }, 100)
          }
        }
      ]

      const right = [
        {
          name: '删除',
          type: 'danger',
          isPop: true,
          popTitle: '确认是否删除吗?',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/commodity/farmTravelRoute/${this.detail.id}`,
              isPost: false
            })

            setTimeout(() => {
              this.$router.push('/marketingCenter/farmersBrigade')
            }, 100)
          }
        }
      ]

      return {
        left,
        right: this.detail.id && right
      }
    },
    onAdd() {
      this.detail.farmTravelRouteDayVOS.push({ travelRouteDetailVOS: [] })
    },
    renderCustom() {
      return {
        render: () => {
          return (
            <Button
              block={true}
              ghost={true}
              type={'dashed'}
              style={{ marginTop: '10px', border: '1px dashed #62697C' }}
              onClick={this.onAdd}
            >
              <a-icon type="plus" />
              新增一天
            </Button>
          )
        }
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        data={[this.getForm1(), this.getForm2(), ...this.renderTables(), this.renderCustom(), this.getForm4()]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.text-hidden {
  width: 200px;
  margin-right: -50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.TableTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.ant-table-fixed {
  padding-left: 24px !important;
}
</style>
